import React from 'react';
import ApiExplorer from '../components/ApiExplorer.jsx';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';

const ApiExplorerWrapper = (props) => {

    const { globalUrl, serverside } = props;

    const [isAppDataLoaded, setIsAppDataLoaded] = useState(false)
    const location = useLocation()
    const [openapi, setOpenapi] = useState({});

    const base64_decode = (str) => {
        return decodeURIComponent(
            atob(str).split("").map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            }).join("")
        );
    };

    useEffect(() => {
        const appid = location.pathname.split("/")[2];
        getAppData(appid);
    }, [location]);

    // Fetch data when appid is available
    const getAppData = (appid) => {

        if (appid === undefined || appid === null || appid.length === 0) {
            return
        }
        const url = `${globalUrl}/api/v1/apps/${appid}/config`;

        fetch(url, {
            credentials: "include",
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            if (response.status !== 200) {
                toast.error("Failed to load app. Please try again.")
                return
            }
            return response.json()
        }).then((responseJson) => {
            if (responseJson.success === true) {
                setIsAppDataLoaded(true)
                handleDecodeOfOpenApiData(responseJson)
            } else {
                toast.error("Failed to get app data. Please try again.")
            }
        }).catch((error) => {
            toast.error("Failed to get app data. Please try again.")
        })
    }


    const handleDecodeOfOpenApiData = (data) => {

        var appexists = false;
        var parsedapp = {};

        if (data.app !== undefined && data.app !== null) {
            var parsedBaseapp = ""
            try {
                parsedBaseapp = base64_decode(data.app)
            } catch (e) {
                parsedBaseapp = data
            }

            parsedapp = JSON.parse(parsedBaseapp)
            parsedapp.name = parsedapp.name.replaceAll("_", " ");

            appexists =
                parsedapp.name !== undefined &&
                parsedapp.name !== null &&
                parsedapp.name.length !== 0;
        }

        if (data.openapi === undefined || data.openapi === null) {
            return;
        }

        var parsedDecoded = ""
        try {
            parsedDecoded = base64_decode(data.openapi)
        } catch (e) {
            parsedDecoded = data
        }

        parsedapp = JSON.parse(parsedDecoded);
        data = parsedapp.body === undefined ? parsedapp : JSON.parse(parsedapp.body);
        if (data.id === undefined || data.id === null) {
            return;
        } else {
            setOpenapi(data)
        }
    };

    return (
        <div className="api-explorer-wrapper">
            {isAppDataLoaded ? (
                <ApiExplorer openapi={openapi} />
            ) : (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', }}>
                    <CircularProgress />
                </div>
            )}
        </div>
    );
};

export default ApiExplorerWrapper;