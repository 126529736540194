import { useState, useEffect } from "react";
import { CopyBlock, github } from 'react-code-blocks';
import ReactJson from 'react-json-view-ssr';
import {
    Chip,
    Paper,
    Button,
    ButtonGroup,
    Box,
    InputLabel,
    MenuItem,
    Drawer,
    SwipeableDrawer,
    TextField,
    Tabs,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    Select,
    Typography,
    CssBaseline,
    styled,
    TableRow,
} from '@mui/material';
import { Sheet, Option } from '@mui/joy';
// import CodeMirror from '@uiw/react-codemirror';
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import {
    experimental_extendTheme as materialExtendTheme,
    Experimental_CssVarsProvider as MaterialCssVarsProvider,
    THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles';
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';

const cUrl = async (url, headers, method, data) => {
    const values = await fetch(url, {
        method: method,
        headers: headers,
        body: JSON.stringify(data)
    })
    return values
}

const CustomMenu = styled('ul')(({ theme }) => ({
    padding: 0,
    margin: 0,
    '& .MuiMenuItem-root': {
        paddingTop: 0,
        paddingBottom: 0,
    },
}));


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3, padding: 0, backgroundColor: '#1a1a1a' }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const materialTheme = materialExtendTheme();

const ApiExplorer = ({ openapi }) => {

    const [info, setInfo] = useState({});
    const [actions, setActions] = useState([]);
    const [serverurl, setServerUrl] = useState("");
    const [appdata, setAppdata] = useState({})
    const [moredata, setMoredata] = useState(appdata)
    const [data, setData] = useState(actions)
    const [curData, setCurData] = useState(data[0])
    const [baseurl, setBaseurl] = useState(serverurl)
    const [valueResponse, setIsResponse] = useState(false)
    const [tabValues, setTabValues] = useState({});
    const [state, setState] = useState({ bottom: false })
    const [rows, setRows] = useState([{ key: '', value: '' }]);
    const [paramRows, setParamRows] = useState([{ key: '', value: '' }])
    const [reqUrl, setReqUrl] = useState([])
    const [query, setQuery] = useState('');
    const [curIndex, setCurIndex] = useState(0)
    const [selectedAction, setSelectedAction] = useState(actions[0])
    const RequestMethods = ["GET", "POST", "PUT", "DELETE", "PATCH", "OPTIONS", "HEAD", "CONNECT", "TRACE"]
    const [selectedMethod, setSelectedMethod] = useState(selectedAction?.method || RequestMethods[0]);
    const [curTab, setCurTab] = useState(0)
    const [CodeMirror, setCodeMirror] = useState(null);
    // const [json, setJson] = useState(null)

    // make json a dummy function
    const [json, setJson] = useState(null);

    useEffect(() => {
        Promise.all([
            import('@uiw/react-codemirror'),
            import('@codemirror/lang-json')
          ]).then(([cmModule, jsonModule]) => {
            setCodeMirror(() => cmModule.default);
            setJson(() => jsonModule.json);
        });

        if (openapi !== undefined && openapi !== null) {
            parseIncomingOpenapiData(openapi)
        }
    }, [openapi])
    console.log("openapi", openapi)

    useEffect(() => {
        if (actions.length > 0) {
            setSelectedAction(actions[0]);
        }
    }, [actions]);

    useEffect(() => {
        if (selectedAction) {
            setSelectedMethod(selectedAction.method);
        }
    }, [selectedAction]);

    const parseIncomingOpenapiData = (data) => {

        var parentUrl = "";

        if (data.info !== null && data.info !== undefined) {
            setInfo(data.info)
        }

        try {
            if (data.info !== null && data.info !== undefined) {
                if (data.info.title !== undefined && data.info.title !== null) {
                    if (data.info.title.endsWith(" API")) {
                        data.info.title = data.info.title.substring(0, data.info.title.length - 4)
                    } else if (data.info.title.endsWith("API")) {
                        data.info.title = data.info.title.substring(0, data.info.title.length - 3)
                    }
                }

                document.title = "Apps - " + data.info.title;

                if (data.info["x-categories"] !== undefined && data.info["x-categories"].length > 0) {
                    if (Array.isArray(data.info["x-categories"])) {
                    } else {
                    }
                }
            }
        } catch (e) {
        }

        try {
            if (data.tags !== undefined && data.tags.length > 0) {
                var newtags = [];
                for (let tagkey in data.tags) {
                    if (data.tags[tagkey]?.name.length > 50) {

                        continue;
                    }

                    newtags.push(data.tags[tagkey]?.name);
                }

                if (newtags.length > 10) {
                    newtags = newtags.slice(0, 9);
                }
            }
        } catch (e) {

        }

        // This is annoying (:
        // Weird generator problems to be handle
        var securitySchemes = undefined
        try {
            if (data.securitySchemes !== undefined) {
                securitySchemes = data.securitySchemes
                if (securitySchemes === undefined) {
                    securitySchemes = data.securityDefinitions;
                }
            }

            if (securitySchemes === undefined && data.components !== undefined) {
                securitySchemes = data.components.securitySchemes;
                if (securitySchemes === undefined) {
                    securitySchemes = data.components.securityDefinitions;
                }
            }
        } catch (e) {
        }


        const allowedfunctions = [
            "GET",
            "CONNECT",
            "HEAD",
            "DELETE",
            "POST",
            "PATCH",
            "PUT",
        ];


        var newActions = [];
        var wordlist = {};
        var all_categories = [];
        var parentUrl = ""

        if (data.paths !== null && data.paths !== undefined) {
            for (let [path, pathvalue] of Object.entries(data.paths)) {

                for (let [method, methodvalue] of Object.entries(pathvalue)) {
                    if (methodvalue === null) {
                        continue;
                    }

                    if (!allowedfunctions.includes(method.toUpperCase())) {
                        // Typical YAML issue
                        if (method !== "parameters") {
                            //toast("Skipped method (not allowed): " + method);
                        }
                        continue;
                    }

                    var tmpname = methodvalue.summary;
                    if (
                        methodvalue.operationId !== undefined &&
                        methodvalue.operationId !== null &&
                        methodvalue.operationId.length > 0 &&
                        (tmpname === undefined || tmpname.length === 0)
                    ) {
                        tmpname = methodvalue.operationId;
                    }

                    if (tmpname !== undefined && tmpname !== null) {
                        tmpname = tmpname.replaceAll(".", " ");
                    }

                    if ((tmpname === undefined || tmpname === null) && methodvalue.description !== undefined && methodvalue.description !== null && methodvalue.description.length > 0) {
                        tmpname = methodvalue.description.replaceAll(".", " ").replaceAll("_", " ")
                    }

                    var newaction = {
                        name: tmpname,
                        description: methodvalue.description,
                        url: path,
                        file_field: "",
                        method: method.toUpperCase(),
                        headers: "",
                        queries: [],
                        paths: [],
                        body: "",
                        errors: [],
                        example_response: "",
                        action_label: "No Label",
                        required_bodyfields: [],
                    };

                    if (methodvalue["x-label"] !== undefined && methodvalue["x-label"] !== null) {
                        // FIX: Map labels only if they're actually in the category list
                        newaction.action_label = methodvalue["x-label"]
                    }

                    if (methodvalue["x-required-fields"] !== undefined && methodvalue["x-required-fields"] !== null) {
                        newaction.required_bodyfields = methodvalue["x-required-fields"]
                    }

                    if (newaction.url !== undefined && newaction.url !== null && newaction.url.includes("_shuffle_replace_")) {
                        //const regex = /_shuffle_replace_\d/i;
                        const regex = /_shuffle_replace_\d+/i

                        newaction.url = newaction.url.replaceAll(new RegExp(regex, 'g'), "")
                    }

                    // Finding category
                    if (path.includes("/")) {
                        const pathsplit = path.split("/");
                        // Stupid way of finding a category/grouping
                        for (let splitkey in pathsplit) {
                            if (pathsplit[splitkey].includes("_shuffle_replace_")) {
                                //const regex = /_shuffle_replace_\d/i;
                                const regex = /_shuffle_replace_\d+/i
                                pathsplit[splitkey] = pathsplit[splitkey].replaceAll(new RegExp(regex, 'g'), "")
                            }

                            if (
                                pathsplit[splitkey].length > 0 &&
                                pathsplit[splitkey] !== "v1" &&
                                pathsplit[splitkey] !== "v2" &&
                                pathsplit[splitkey] !== "api" &&
                                pathsplit[splitkey] !== "1.0" &&
                                pathsplit[splitkey] !== "apis"
                            ) {
                                newaction["category"] = pathsplit[splitkey];
                                if (!all_categories.includes(pathsplit[splitkey])) {
                                    all_categories.push(pathsplit[splitkey]);
                                }
                                break;
                            }
                        }
                    }

                    if (path === "/files/{file_id}/content") {
                    }


                    // Typescript? I think not ;)
                    if (methodvalue["requestBody"] !== undefined) {
                        if (methodvalue["requestBody"]["$ref"] !== undefined && methodvalue["requestBody"]["$ref"] !== null) {
                            // Handle ref
                            const parameter = handleGetRef({ $ref: methodvalue["requestBody"]["$ref"] }, data);
                            setAppdata(prevState => ({
                                ...prevState,
                                [methodvalue.summary]: { ...parameter }
                            }))
                            if (parameter.content !== undefined && parameter.content !== null) {
                                methodvalue["requestBody"]["content"] = parameter.content
                            }
                        }

                        if (methodvalue["requestBody"]["content"] !== undefined) {
                            // Handle content - XML or JSON
                            //
                            if (
                                methodvalue["requestBody"]["content"]["application/json"] !==
                                undefined
                            ) {
                                //newaction["headers"] = ""
                                //"Content-Type=application/json\nAccept=application/json";
                                if (
                                    methodvalue["requestBody"]["content"]["application/json"]["schema"] !== undefined && methodvalue["requestBody"]["content"]["application/json"]["schema"] !== null
                                ) {

                                    try {
                                        if (methodvalue["requestBody"]["content"]["application/json"]["schema"]["properties"] !== undefined) {
                                            // Read out properties from a JSON object
                                            const jsonObject = getJsonObject(methodvalue["requestBody"]["content"]["application/json"]["schema"]["properties"])
                                            if (jsonObject !== undefined && jsonObject !== null) {
                                                try {
                                                    newaction["body"] = JSON.stringify(jsonObject, null, 2)
                                                } catch (e) {
                                                }
                                            }


                                            //newaction["body"] = JSON.stringify(jsonObject, null, 2);

                                            var tmpobject = {};
                                            for (let prop of methodvalue["requestBody"]["content"]["application/json"]["schema"]["properties"]) {
                                                tmpobject[prop] = `\$\{${prop}\}`;
                                            }
                                            for (let subkey in methodvalue["requestBody"]["content"]["application/json"]["schema"]["required"]) {
                                                const tmpitem = methodvalue["requestBody"]["content"]["application/json"]["schema"]["required"][subkey];
                                                tmpobject[tmpitem] = `\$\{${tmpitem}\}`;
                                            }

                                            newaction["body"] = JSON.stringify(tmpobject, null, 2);

                                        } else if (

                                            methodvalue["requestBody"]["content"]["application/json"]["schema"]["$ref"] !== undefined && methodvalue["requestBody"]["content"]["application/json"]["schema"]["$ref"] !== null) {
                                            const retRef = handleGetRef(methodvalue["requestBody"]["content"]["application/json"]["schema"], data);
                                            setAppdata(prevState => ({
                                                ...prevState,
                                                [methodvalue.summary]: { ...retRef }
                                            }))
                                            var newbody = {};
                                            // Can handle default, required, description and type
                                            for (let propkey in retRef.properties) {

                                                const parsedkey = propkey.replaceAll(" ", "_").toLowerCase();
                                                newbody[parsedkey] = "${" + parsedkey + "}";
                                            }

                                            newaction["body"] = JSON.stringify(newbody, null, 2);
                                        }
                                    } catch (e) {
                                    }
                                }
                            } else if (
                                methodvalue["requestBody"]["content"]["application/xml"] !==
                                undefined
                            ) {
                                //newaction["headers"] = ""
                                //"Content-Type=application/xml\nAccept=application/xml";
                                if (
                                    methodvalue["requestBody"]["content"]["application/xml"][
                                    "schema"
                                    ] !== undefined &&
                                    methodvalue["requestBody"]["content"]["application/xml"][
                                    "schema"
                                    ] !== null
                                ) {
                                    try {
                                        if (
                                            methodvalue["requestBody"]["content"]["application/xml"][
                                            "schema"
                                            ]["properties"] !== undefined
                                        ) {
                                            var tmpobject = {};
                                            for (let [prop, propvalue] of Object.entries(methodvalue["requestBody"]["content"]["application/xml"]["schema"]["properties"])) {

                                                tmpobject[prop] = `\$\{${prop}\}`;
                                            }

                                            for (let [subkey, subkeyval] in Object.entries(methodvalue["requestBody"]["content"]["application/xml"]["schema"]["required"])) {
                                                const tmpitem =
                                                    methodvalue["requestBody"]["content"][
                                                    "application/xml"
                                                    ]["schema"]["required"][subkey];
                                                tmpobject[tmpitem] = `\$\{${tmpitem}\}`;
                                            }

                                            //newaction["body"] = XML.stringify(tmpobject, null, 2)
                                        }
                                    } catch (e) {
                                    }
                                }
                            } else {
                                if (methodvalue["requestBody"]["content"]["example"] !== undefined) {
                                    if (methodvalue["requestBody"]["content"]["example"]["example"] !== undefined) {
                                        newaction["body"] = methodvalue["requestBody"]["content"]["example"]["example"]
                                    }
                                }

                                if (methodvalue["requestBody"]["content"]["multipart/form-data"] !== undefined) {
                                    if (
                                        methodvalue["requestBody"]["content"][
                                        "multipart/form-data"
                                        ]["schema"] !== undefined &&
                                        methodvalue["requestBody"]["content"][
                                        "multipart/form-data"
                                        ]["schema"] !== null
                                    ) {
                                        try {
                                            if (methodvalue["requestBody"]["content"]["multipart/form-data"]["schema"]["type"] === "object") {
                                                const fieldname =
                                                    methodvalue["requestBody"]["content"][
                                                    "multipart/form-data"
                                                    ]["schema"]["properties"]["fieldname"];

                                                if (fieldname !== undefined) {
                                                    newaction.file_field = fieldname["value"];
                                                } else {
                                                    for (const [subkey, subvalue] of Object.entries(methodvalue["requestBody"]["content"]["multipart/form-data"]["schema"]["properties"])) {
                                                        if (subkey.includes("file")) {
                                                            newaction.file_field = subkey
                                                            break
                                                        }
                                                    }

                                                    if (newaction.file_field === undefined || newaction.file_field === null || newaction.file_field.length === 0) {
                                                    }
                                                }
                                            } else {
                                            }
                                        } catch (e) {
                                        }
                                    }
                                } else {
                                    var schemas = [];
                                    const content = methodvalue["requestBody"]["content"];
                                    if (content !== undefined && content !== null) {
                                        for (const [subkey, subvalue] of Object.entries(content)) {
                                            if (subvalue["schema"] !== undefined && subvalue["schema"] !== null) {
                                                if (subvalue["schema"]["$ref"] !== undefined && subvalue["schema"]["$ref"] !== null) {
                                                    if (!schemas.includes(subvalue["schema"]["$ref"])) {
                                                        schemas.push(subvalue["schema"]["$ref"]);
                                                    }
                                                }
                                            } else {
                                                if (subvalue["example"] !== undefined && subvalue["example"] !== null) {
                                                    newaction["body"] = subvalue["example"]
                                                } else {
                                                }
                                            }
                                        }
                                    }

                                    try {
                                        if (schemas.length === 1) {
                                            const parameter = handleGetRef({ $ref: schemas[0] }, data);
                                            if (parameter.properties !== undefined && parameter["type"] === "object") {

                                                var newbody = {};
                                                for (let propkey in parameter.properties) {
                                                    const parsedkey = propkey.replaceAll(" ", "_").toLowerCase();
                                                    if (parameter.properties[propkey].type === undefined) {
                                                        continue;
                                                    }

                                                    if (parameter.properties[propkey].type === "string") {
                                                        if (
                                                            parameter.properties[propkey].description !==
                                                            undefined
                                                        ) {
                                                            newbody[parsedkey] =
                                                                parameter.properties[propkey].description;
                                                        } else {
                                                            newbody[parsedkey] = "";
                                                        }
                                                    } else if (
                                                        parameter.properties[propkey].type.includes("int") ||
                                                        parameter.properties[propkey].type.includes("uint64")
                                                    ) {
                                                        newbody[parsedkey] = 0;
                                                    } else if (
                                                        parameter.properties[propkey].type.includes("boolean")
                                                    ) {
                                                        newbody[parsedkey] = false;
                                                    } else if (
                                                        parameter.properties[propkey].type.includes("array")
                                                    ) {
                                                        newbody[parsedkey] = [];
                                                    } else {
                                                        newbody[parsedkey] = [];
                                                    }
                                                }

                                                newaction["body"] = JSON.stringify(newbody, null, 2);
                                            } else {
                                            }
                                        }
                                    } catch (e) {
                                    }
                                }
                            }
                        }
                    }

                    if (
                        methodvalue.responses !== undefined &&
                        methodvalue.responses !== null
                    ) {
                        if (methodvalue.responses.default !== undefined) {
                            if (methodvalue.responses.default.content !== undefined) {
                                if (
                                    methodvalue.responses.default.content["text/plain"] !==
                                    undefined
                                ) {
                                    if (
                                        methodvalue.responses.default.content["text/plain"]["schema"] !== undefined) {
                                        if (methodvalue.responses.default.content["text/plain"]["schema"]["example"] !== undefined) {
                                            newaction.example_response = methodvalue.responses.default.content["text/plain"]["schema"]["example"]
                                        }

                                        if (methodvalue.responses.default.content["text/plain"]["schema"]["format"] === "binary" && methodvalue.responses.default.content["text/plain"]["schema"]["type"] === "string") {
                                            newaction.example_response = "shuffle_file_download"
                                        }
                                    }
                                }
                            }
                        } else {
                            var selectedReturn = "";
                            if (methodvalue.responses["200"] !== undefined) {
                                selectedReturn = "200";
                            } else if (methodvalue.responses["201"] !== undefined) {
                                selectedReturn = "201";
                            }

                            // Parsing examples. This should be standardized lol
                            if (methodvalue.responses[selectedReturn] !== undefined) {
                                const selectedExample = methodvalue.responses[selectedReturn];
                                if (selectedExample["content"] !== undefined) {
                                    if (
                                        selectedExample["content"]["application/json"] !== undefined
                                    ) {
                                        if (
                                            selectedExample["content"]["application/json"]["schema"] !== undefined &&
                                            selectedExample["content"]["application/json"]["schema"] !== null
                                        ) {

                                            if (selectedExample["content"]["application/json"]["schema"]["properties"] !== undefined && selectedExample["content"]["application/json"]["schema"]["properties"] !== null) {
                                                const jsonObject = getJsonObject(selectedExample["content"]["application/json"]["schema"]["properties"])
                                                if (jsonObject !== undefined && jsonObject !== null) {
                                                    try {
                                                        newaction.example_response = JSON.stringify(jsonObject, null, 2)
                                                    } catch (e) {
                                                    }
                                                }
                                            }

                                            if (selectedExample["content"]["application/json"]["schema"]["$ref"] !== undefined) {
                                                const parameter = handleGetRef(
                                                    selectedExample["content"]["application/json"][
                                                    "schema"
                                                    ],
                                                    data
                                                );
                                                if (parameter.properties !== undefined && parameter["type"] === "object") {
                                                    var newbody = {};
                                                    for (let propkey in parameter.properties) {

                                                        const parsedkey = propkey.replaceAll(" ", "_").toLowerCase();
                                                        if (parameter.properties[propkey].type === undefined) {
                                                            continue;
                                                        }

                                                        if (
                                                            parameter.properties[propkey].type === "string"
                                                        ) {
                                                            if (
                                                                parameter.properties[propkey].description !==
                                                                undefined
                                                            ) {
                                                                newbody[parsedkey] =
                                                                    parameter.properties[propkey].description;
                                                            } else {
                                                                newbody[parsedkey] = "";
                                                            }
                                                        } else if (
                                                            parameter.properties[propkey].type.includes("int")
                                                        ) {
                                                            newbody[parsedkey] = 0;
                                                        } else if (
                                                            parameter.properties[propkey].type.includes(
                                                                "boolean"
                                                            )
                                                        ) {
                                                            newbody[parsedkey] = false;
                                                        } else if (
                                                            parameter.properties[propkey].type.includes(
                                                                "array"
                                                            )
                                                        ) {

                                                            //const parameter = handleGetRef(selectedExample["content"]["application/json"]["schema"], data)
                                                            newbody[parsedkey] = [];
                                                        } else {
                                                            newbody[parsedkey] = [];
                                                        }
                                                    }
                                                    newaction.example_response = JSON.stringify(
                                                        newbody,
                                                        null,
                                                        2
                                                    );
                                                } else {
                                                }
                                            } else {
                                                // Just selecting the first one. bleh.
                                                if (
                                                    selectedExample["content"]["application/json"][
                                                    "schema"
                                                    ]["allOf"] !== undefined
                                                ) {
                                                    var selectedComponent =
                                                        selectedExample["content"]["application/json"][
                                                        "schema"
                                                        ]["allOf"];
                                                    if (selectedComponent.length >= 1) {
                                                        selectedComponent = selectedComponent[0];

                                                        const parameter = handleGetRef(
                                                            selectedComponent,
                                                            data
                                                        );
                                                        if (parameter.properties !== undefined && parameter["type"] === "object") {
                                                            var newbody = {};
                                                            for (let propkey in parameter.properties) {
                                                                const parsedkey = propkey.replaceAll(" ", "_").toLowerCase();
                                                                if (
                                                                    parameter.properties[propkey].type ===
                                                                    undefined
                                                                ) {
                                                                    continue;
                                                                }

                                                                if (
                                                                    parameter.properties[propkey].type ===
                                                                    "string"
                                                                ) {
                                                                    if (
                                                                        parameter.properties[propkey]
                                                                            .description !== undefined
                                                                    ) {
                                                                        newbody[parsedkey] =
                                                                            parameter.properties[propkey].description;
                                                                    } else {
                                                                        newbody[parsedkey] = "";
                                                                    }
                                                                } else if (
                                                                    parameter.properties[propkey].type.includes(
                                                                        "int"
                                                                    )
                                                                ) {
                                                                    newbody[parsedkey] = 0;
                                                                } else if (
                                                                    parameter.properties[propkey].type.includes(
                                                                        "boolean"
                                                                    )
                                                                ) {
                                                                    newbody[parsedkey] = false;
                                                                } else {
                                                                    newbody[parsedkey] = [];
                                                                }
                                                            }

                                                            newaction.example_response = JSON.stringify(
                                                                newbody,
                                                                null,
                                                                2
                                                            );
                                                            //newaction.example_response = JSON.stringify(parameter.properties, null, 2)
                                                        } else {
                                                            //newaction.example_response = parameter.properties

                                                        }
                                                    } else {
                                                    }
                                                } else if (
                                                    selectedExample["content"]["application/json"][
                                                    "schema"
                                                    ]["properties"] !== undefined
                                                ) {
                                                    if (
                                                        selectedExample["content"]["application/json"][
                                                        "schema"
                                                        ]["properties"]["data"] !== undefined
                                                    ) {
                                                        const parameter = handleGetRef(
                                                            selectedExample["content"]["application/json"][
                                                            "schema"
                                                            ]["properties"]["data"],
                                                            data
                                                        );
                                                        if (parameter.properties !== undefined && parameter["type"] === "object") {
                                                            var newbody = {};
                                                            for (let propkey in parameter.properties) {
                                                                const parsedkey = propkey
                                                                    .replaceAll(" ", "_")
                                                                    .toLowerCase();
                                                                if (
                                                                    parameter.properties[propkey].type ===
                                                                    undefined
                                                                ) {
                                                                    continue;
                                                                }

                                                                if (
                                                                    parameter.properties[propkey].type ===
                                                                    "string"
                                                                ) {
                                                                    if (
                                                                        parameter.properties[propkey]
                                                                            .description !== undefined
                                                                    ) {
                                                                        newbody[parsedkey] =
                                                                            parameter.properties[propkey].description;
                                                                    } else {
                                                                        newbody[parsedkey] = "";
                                                                    }
                                                                } else if (
                                                                    parameter.properties[propkey].type.includes(
                                                                        "int"
                                                                    )
                                                                ) {
                                                                    newbody[parsedkey] = 0;
                                                                } else {
                                                                    newbody[parsedkey] = [];
                                                                }
                                                            }

                                                            newaction.example_response = JSON.stringify(
                                                                newbody,
                                                                null,
                                                                2
                                                            );
                                                            //newaction.example_response = JSON.stringify(parameter.properties, null, 2)
                                                        } else {
                                                            //newaction.example_response = parameter.properties
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    for (let paramkey in methodvalue.parameters) {
                        const parameter = handleGetRef(methodvalue.parameters[paramkey], data);
                        setAppdata(prevState => ({
                            ...prevState,
                            [methodvalue.summary]: { ...parameter }
                        }))

                        if (parameter.in === "query") {
                            var tmpaction = {
                                description: parameter.description,
                                name: parameter?.name,
                                required: parameter.required,
                                in: "query",
                            };

                            if (parameter.example !== undefined && parameter.example !== null) {
                                tmpaction.example = parameter.example
                            }

                            if (parameter.required === undefined) {
                                tmpaction.required = false;
                            }

                            newaction.queries.push(tmpaction);
                        } else if (parameter.in === "path") {
                            // FIXME - parse this to the URL too
                            newaction.paths.push(parameter?.name);

                            // FIXME: This doesn't follow OpenAPI3 exactly.
                            // https://swagger.io/docs/specification/describing-request-body/
                            // https://swagger.io/docs/specification/describing-parameters/
                            // Need to split the data.
                        } else if (parameter.in === "body") {
                            // FIXME: Add tracking for components
                            // E.G: https://raw.githubusercontent.com/owentl/Shuffle/master/gosecure.yaml
                            if (parameter.example !== undefined && parameter.example !== null) {
                                if (newaction.body === undefined || newaction.body === null || newaction.body.length < 5) {
                                    newaction.body = parameter.example
                                }
                            }
                        } else if (parameter.in === "header") {
                            newaction.headers += `${parameter?.name}=${parameter.example}\n`;
                        } else {
                        }
                    }

                    // Check if body is valid JSON. 
                    if (newaction.body !== undefined && newaction.body !== null && newaction.body.length > 0) {
                        // Trim starting / ending newlines, spaces and tabs
                        newaction.body = newaction.body.trim()

                    }


                    if (newaction?.name === "" || newaction?.name === undefined) {
                        // Find a unique part of the string
                        // FIXME: Looks for length between /, find the one where they differ
                        // Should find others with the same START to their path
                        // Make a list of reserved names? Aka things that show up only once
                        if (Object.getOwnPropertyNames(wordlist).length === 0) {
                            for (let [newpath, pathvalue] of Object.entries(data.paths)) {
                                const newpathsplit = newpath.split("/");

                                for (let splitkey in newpathsplit) {
                                    const pathitem = newpathsplit[splitkey].toLowerCase();
                                    if (wordlist[pathitem] === undefined) {
                                        wordlist[pathitem] = 1;
                                    } else {
                                        wordlist[pathitem] += 1;
                                    }
                                }
                            }
                        }

                        // Remove underscores and make it normal with upper case etc
                        const urlsplit = path.split("/");
                        if (urlsplit.length > 0) {
                            var curname = "";
                            for (let urlkey in urlsplit) {
                                var subpath = urlsplit[urlkey];
                                if (wordlist[subpath] > 2 || subpath.length < 1) {
                                    continue;
                                }

                                curname = subpath;
                                break;
                            }

                            // FIXME: If name exists,
                            // FIXME: Check if first part of parsedname is verb, otherwise use method
                            const parsedname = curname
                                .split("_")
                                .join(" ")
                                .split("-")
                                .join(" ")
                                .split("{")
                                .join(" ")
                                .split("}")
                                .join(" ")
                                .trim();
                            if (parsedname.length === 0) {
                                newaction.errors.push("Missing name");
                            } else {
                                const newname =
                                    method.charAt(0).toUpperCase() +
                                    method.slice(1) +
                                    " " +
                                    parsedname;
                                const searchactions = newActions.find(
                                    (data) => data?.name === newname
                                );

                                if (searchactions !== undefined) {
                                    newaction.errors.push("Missing name");
                                } else {
                                    newaction.name = newname;
                                }
                            }
                        } else {
                            newaction.errors.push("Missing name");
                        }
                    }

                    //newaction.action_label = "No Label"
                    newActions.push(newaction);
                }
            }


            if (data.servers !== undefined && data.servers.length > 0) {
                var firstUrl = data.servers[0].url;
                if (
                    firstUrl.includes("{") &&
                    firstUrl.includes("}") &&
                    data.servers[0].variables !== undefined
                ) {
                    const regex = /{\w+}/g;
                    const found = firstUrl.match(regex);
                    if (found !== null) {
                        for (let foundkey in found) {
                            const item = found[foundkey].slice(1, found[foundkey].length - 1);
                            const foundVar = data.servers[0].variables[item];
                            if (foundVar["default"] !== undefined) {
                                firstUrl = firstUrl.replace(found[foundkey], foundVar["default"]);
                            }
                        }
                    }
                }

                if (firstUrl.endsWith("/")) {
                    parentUrl = firstUrl.slice(0, firstUrl.length - 1)
                } else {
                    parentUrl = firstUrl
                }
            }
        }
        var prefixCheck = "/v1"
        if (parentUrl.includes("/")) {
            const urlsplit = parentUrl.split("/")
            if (urlsplit.length > 2) {
                // Skip if http:// in it too
                prefixCheck = "/" + urlsplit.slice(3).join("/")
            }

            if (prefixCheck.length > 0 && prefixCheck !== "/" && prefixCheck.startsWith("/")) {
                for (var actionKey in newActions) {
                    const action = newActions[actionKey]

                    if (action.url !== undefined && action.url !== null && action.url.startsWith(prefixCheck)) {
                        newActions[actionKey].url = action.url.slice(prefixCheck.length, action.url.length)
                    }
                }
            }
        }

        setServerUrl(parentUrl)
        var newActions2 = []
        // Remove with duplicate action URLs
        for (var actionKey in newActions) {
            const action = newActions[actionKey]
            if (action.url === undefined || action.url === null) {
                continue
            }

            var found = false
            for (var actionKey2 in newActions2) {
                const action2 = newActions2[actionKey2]
                if (action2.url === undefined || action2.url === null) {
                    continue
                }

                if (action.url === action2.url) {
                    found = true
                    break
                }
            }

            if (!found) {
                newActions2.push(action)
            } else {
                newActions2.push(action)
            }
        }

        newActions = newActions2

        // Rearrange them by which has action_label
        const firstActions = newActions.filter(data => data.action_label !== undefined && data.action_label !== null && data.action_label !== "No Label")
        const secondActions = newActions.filter(data => data.action_label === undefined || data.action_label === null || data.action_label === "No Label")
        newActions = firstActions.concat(secondActions)
        setActions(newActions);
        //data.paths[item.url][item.method.toLowerCase()]["x-label"] = item.action_label
    };

    // Loops through properties to find the actual JSON output to use
    const getJsonObject = (properties) => {
        // Loop inside the JSON object and get the value of each key
        let jsonObject = {};
        for (let key in properties) {
            const property = properties[key];

            let subloop = false
            if (property.hasOwnProperty("type")) {
                if (property.type === "object" || property.type === "array") {
                    subloop = true
                }
            }

            if (subloop) {
                if (property.hasOwnProperty("items") && property.items.hasOwnProperty("properties")) {

                    const jsonret = getJsonObject(property.items.properties);
                    if (property.type === "array") {
                        jsonObject[key] = [jsonret];
                    } else {
                        jsonObject[key] = jsonret;
                    }
                } else {
                    if (property.hasOwnProperty("properties")) {
                        const jsonret = getJsonObject(property.properties);
                        if (property.type === "array") {
                            jsonObject[key] = [jsonret];
                        } else {
                            jsonObject[key] = jsonret;
                        }
                    } else {
                    }
                }

            } else {
                if (property.hasOwnProperty("example")) {
                    jsonObject[key] = property.example;
                } else if (property.hasOwnProperty("enum") && property.enum.length > 0) {
                    jsonObject[key] = property.enum[0];
                } else if (property.hasOwnProperty("default")) {
                    jsonObject[key] = property.default;
                } else if (property.hasOwnProperty("maximum")) {
                    jsonObject[key] = property.maximum;
                } else if (property.hasOwnProperty("minimum")) {
                    jsonObject[key] = property.minimum;
                } else if (property.hasOwnProperty("type")) {
                    if (property.type === "integer" || property.type === "number") {
                        jsonObject[key] = 0;
                    } else if (property.type === "boolean") {
                        jsonObject[key] = false;
                    } else if (property.type === "string") {
                        jsonObject[key] = "";
                    } else {
                    }
                } else {
                }
            }
        }

        return jsonObject
    };
    const handleGetRef = (parameter, data) => {
        try {
            if (parameter === null || parameter["$ref"] === undefined) {
                return parameter;
            }
        } catch (e) {
            return parameter;
        }

        const paramsplit = parameter["$ref"].split("/");
        if (paramsplit[0] !== "#") {
            return parameter;
        }

        var newitem = data;
        for (let paramkey in paramsplit) {
            var tmpparam = paramsplit[paramkey];
            if (tmpparam === "#") {
                continue;
            }

            if (newitem[tmpparam] === undefined) {
                return parameter;
            }

            newitem = newitem[tmpparam];
        }
        return newitem;
    };

    useEffect(() => {
        const initalTabValues = {}
        const urls = data.map(item => item?.url)
        data.map((v, i) => {
            initalTabValues[`tab-set-${i}`] = 0;
        })
        setTabValues(initalTabValues)
        setReqUrl(urls)
    }, [data])


    const addRow = (setRow, row) => {
        setRow([...row, { key: '', value: '' }]);
    };

    const handleInputChange = (index, field, value, setRow, row) => {
        const newRows = [...row];
        newRows[index][field] = value;
        setRow(newRows);
    };

    useEffect(() => {
        updateQueryString(paramRows)
    }, [paramRows])

    const updateQueryString = (paramRows) => {
        setQuery(paramRows.filter(row => row.key && row.value).map(row => `${encodeURIComponent(row.key)}=${encodeURIComponent(row.value)}`).join('&'))
        if (query !== '') {
            setReqUrl(`${curData?.url}?${query}`)
        } else {
        }
    }

    const handleUrl = (index) => {
        return data[index]?.url
    }

    const genrateHeaders = () => {
        const obj = rows.reduce((acc, row) => {
            if (row.key && row.value) {
                acc[String(row.key)] = row.value;
            }
            return acc;
        }, {})
        return obj
    }

    const handleChangeTab = (event, newValue) => {
        setCurTab(newValue);
    };

    const anchor = "bottom"

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
        handleExecution()
    };

    const handleExecution = (url, data, index) => {
        const value = cUrl(`${baseurl + url}`, genrateHeaders(), data['method'], formData(index))
        value.then(response => response.json()).then(data => setIsResponse(data))
    }

    //name
    const handleChange = (index, action) => {
        setReqUrl(data[index]?.url)
        setCurData(data[index])
        setCurIndex(index)
        setSelectedAction(action)
        setSelectedMethod(action?.method)
    }

    const formData = (index) => {
        const val = {};
        if (data[index] && moredata[data[index]?.name] && moredata[data[index]?.name].properties) {
            Object.entries(moredata[data[index]?.name].properties).forEach(([key, value]) => {
                val[key] = value.example || value.default;
            });
        }

        return val;
    };

    const genCode = () => {
        return `curl --location "${baseurl + curData['url']}"   \\
                -X "${curData['method']}"   \\
                -H "X-API-VALUE"    \\
                -d '{{BODY}}'`
    }

    return (
        <div>
            <div style={{ display: "flex", paddingLeft: 20, borderBottom: "1px solid black", position: "sticky" }}>
                <h1>
                    Shuffle - {info?.title ? info.title : "API Explorer"}
                </h1>
            </div>
            <div style={{ display: "flex", flexDirection: "row", }}>
                <div style={{ display: "flex", flexDirection: "column", width: 300 }}>
                    <Paper style={{ display: "flex", flexDirection: "column", width: "100%", position: "sticky", top: 0, marginLeft: 20, overflowX: "hidden" }}>
                        <ButtonGroup
                            orientation="vertical"
                            variant="text"
                            style={{ backgroundColor: 'transparent' }}>
                            {actions.map((action, index) => (
                                <Button
                                    key={index}
                                    style={{
                                        padding: "15px 0",
                                        color: "none",
                                        fontWeight: "normal",
                                        textTransform: 'none',
                                        backgroundColor: '#1a1a1a',
                                        border: 'none',
                                        justifyContent: 'flex-start',
                                        boxShadow: 'none',
                                        pointerEvents: 'auto',
                                    }}
                                    disableRipple={true}
                                    onClick={() => handleChange(index, action)}
                                >
                                    <span style={{ color: "Green", fontSize: "12px", paddingRight: "10px", fontWeight: "bold" }}>{action?.method}</span>
                                    <span style={{ color: "white" }}>{action?.name.replaceAll("_", " ")}</span>
                                </Button>
                            ))}
                        </ButtonGroup>
                    </Paper>
                </div>
                <div style={{ display: "flex", flexDirection: "column", position: "sticky", minWidth: 696, marginRight: 24 }}>
                    <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
                        <JoyCssVarsProvider>
                            <CssBaseline enableColorScheme />
                            <Sheet
                                sx={{
                                    border: '1px solid rgba(73, 73, 73, 1)',
                                    display: "flex",
                                    p: 0.5,
                                    width: 694,
                                    height: 51,
                                    borderRadius: 6,
                                    marginLeft: "40px",
                                    position: "sticky",
                                    marginTop: 2,
                                    backgroundColor: '#212121',
                                }}
                            >
                                <Select
                                    value={selectedMethod}
                                    onChange={(e) => setSelectedMethod(e.target.value)}
                                    sx={{
                                        width: 130,
                                        color: "rgba(2, 203, 112, 1)",
                                        backgroundColor: '#212121',
                                        borderRight: '1px solid rgba(73, 73, 73, 1)',
                                        borderLeft: "1px solid transparent",
                                        borderBottom: "1px solid transparent",
                                        borderTop: "1px solid transparent",
                                        '&:hover': { backgroundColor: '#212121' },
                                    }}
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                padding: 0,
                                                margin: 0,
                                            },
                                        },
                                        MenuListProps: {
                                            sx: {
                                                padding: 0,
                                                margin: 0,
                                            },
                                        },
                                    }}
                                >
                                    <MenuItem
                                        value={selectedMethod}
                                        sx={{
                                            color: "rgba(2, 203, 112, 1)",
                                            backgroundColor: '#212121',
                                            border: 'none',
                                            '&:hover': { backgroundColor: '#262626' },
                                            '&.Mui-selected': {
                                                backgroundColor: 'rgba(2, 203, 112, 1) !important',
                                                color: '#212121',
                                                border: 'none',
                                            },
                                            '&.Mui-focusVisible': {
                                                outline: 'none',
                                            },
                                        }}
                                    >
                                        {selectedMethod}
                                    </MenuItem>
                                    {RequestMethods.filter(method => method != selectedMethod).map((method, index) => (
                                        <MenuItem
                                            key={index}
                                            value={method}
                                            sx={{
                                                color: "rgba(2, 203, 112, 1)",
                                                backgroundColor: '#212121',
                                                border: 'none',
                                                '&:hover': { backgroundColor: '#262626' },
                                                '&.Mui-selected': {
                                                    backgroundColor: 'rgba(2, 203, 112, 1) !important',
                                                    color: '#212121',
                                                    border: 'none',
                                                },
                                                '&.Mui-focusVisible': {
                                                    outline: 'none',
                                                },
                                            }}
                                        >
                                            {method}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <TextField
                                    fullWidth
                                    value={selectedAction?.url}
                                    inputProps={{
                                        style: {
                                            padding: '8px 10px',
                                            backgroundColor: '#212121',
                                            border: 'none',
                                            color: 'rgba(241, 241, 241, 1)',
                                        },
                                    }}
                                    sx={{
                                        height: 51,
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                border: 'none',
                                            },
                                            '&:hover fieldset': {
                                                border: 'none',
                                            },
                                            '&.Mui-focused fieldset': {
                                                border: 'none',
                                            },
                                        },
                                    }}
                                />
                                <Button
                                    variant="contained"
                                    sx={{
                                        width: 107,
                                        height: 35,
                                        borderRadius: 200,
                                        padding: "16px 24px 16px 24px",
                                        backgroundColor: "rgba(2, 203, 112, 0.2)",
                                        color: "rgba(2, 203, 112, 1)",
                                        textTransform: 'none',
                                        '&:hover': { backgroundColor: 'rgba(2, 203, 112, 0.1)' },
                                        margin: 'auto 0 auto auto',
                                    }}
                                    onClick={() => { handleExecution(selectedAction?.url, selectedAction, curIndex) }}
                                >
                                    Send
                                </Button>
                            </Sheet>

                        </JoyCssVarsProvider>
                    </MaterialCssVarsProvider>
                    <Paper style={{ width: 696, marginTop: 16, marginLeft: "40px", position: "sticky", top: "57px", }}>
                        <div style={{ display: "flex", justifyContent: "center", background: "rgba(26, 26, 26, 1)" }}>
                            <Tabs style={{ display: 'flex', justifyContent: 'center', height: 48, width: 693, backgroundColor: 'rgba(26, 26, 26, 1)', gap: 10, }} value={curTab} onChange={(event, newValue) => handleChangeTab(event, newValue)} aria-label="basic tabs example">
                                <Tab style={{ width: 212.84, height: 44, borderRadius: 8, padding: '16px 24px 16px 24px' }} label=<span style={{ textTransform: 'none' }}>Header</span> {...a11yProps(0)} />
                                <Tab style={{ width: 212.84, height: 44, borderRadius: 8, padding: '16px 24px 16px 24px' }} label=<span style={{ textTransform: 'none' }}>Body</span> {...a11yProps(1)} />
                                <Tab style={{ width: 212.84, height: 44, borderRadius: 8, padding: '16px 24px 16px 24px' }} label=<span style={{ textTransform: 'none' }}>Params</span> {...a11yProps(2)} />
                            </Tabs>
                        </div>
                        <CustomTabPanel value={curTab} index={0}>
                            <TableContainer style={{ width: 696, backgroundColor: '#212121', borderRadius: 6, border: "1px solid rgba(73, 73, 73, 1)", }}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow >
                                            <TableCell style={{ border: "1px solid rgba(73, 73, 73, 1)" }}>Key</TableCell>
                                            <TableCell style={{ border: "1px solid rgba(73, 73, 73, 1)" }}>Value</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row, index) => (
                                            <TableRow key={index} sx={{ height: 60 }}>
                                                <TableCell style={{ border: "1px solid rgba(73, 73, 73, 1)", padding: "0px 4px" }}>
                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"
                                                        type="text"
                                                        value={row.key}
                                                        onChange={(e) => handleInputChange(index, 'key', e.target.value, setRows, rows)}
                                                        inputProps={{
                                                            style: {
                                                                backgroundColor: 'rgba(33, 33, 33, 1)',
                                                                padding: "4px 8px",
                                                            }
                                                        }}
                                                        sx={{
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    border: 'none',
                                                                },
                                                                '&:hover fieldset': {
                                                                    border: 'none',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    border: 'none',
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell style={{ border: "1px solid rgba(73, 73, 73, 1)", padding: "0px 4px" }}>
                                                    <TextField
                                                        fullWidth
                                                        type="text"
                                                        value={row.value}
                                                        onChange={(e) => handleInputChange(index, 'value', e.target.value, setRows, rows)}
                                                        inputProps={{
                                                            style: {
                                                                backgroundColor: 'rgba(33, 33, 33, 1)',
                                                                padding: "4px 8px", // Adjust padding to reduce height
                                                            }
                                                        }}
                                                        sx={{
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    border: 'none',
                                                                },
                                                                '&:hover fieldset': {
                                                                    border: 'none',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    border: 'none',
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </TableCell>
                                            </TableRow>

                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Button onClick={() => { addRow(setRows, rows) }} variant="contained" color="primary" style={{ width: 127, height: 35, borderRadius: 200, padding: '16px 24px 16px 24px', marginTop: 26, textTransform: 'none', backgroundColor: 'rgba(255, 132, 68, 0.2)', color: 'rgba(255, 132, 68, 1)' }}>
                                Add Row
                            </Button>
                        </CustomTabPanel>
                        <CustomTabPanel value={curTab} index={1}>

                        {(json !== null) ? (
                            <CodeMirror
                                value={JSON.stringify(formData(curIndex), null, 4)}
                                height="100%"
                                style={{}}
                                extensions={[json()]}
                            />
                            ) : (
                            <CodeMirror
                                value={JSON.stringify(formData(curIndex), null, 4)}
                                height="100%"
                                style={{}}
                            />
                        )}

                        </CustomTabPanel>
                        <CustomTabPanel value={curTab} index={2}>
                            <TableContainer component={Paper} style={{ margin: "" }}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Key</TableCell>
                                            <TableCell>Value</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {paramRows.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <input
                                                        type="text"
                                                        value={row.key}
                                                        onChange={(e) => handleInputChange(index, 'key', e.target.value, setParamRows, paramRows)}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <input
                                                        type="text"
                                                        value={row.value}
                                                        onChange={(e) => handleInputChange(index, 'value', e.target.value, setParamRows, paramRows)}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Button onClick={() => { addRow(setParamRows, paramRows) }} variant="contained" color="primary" style={{ marginTop: '20px' }}>
                                Add Row
                            </Button>
                        </CustomTabPanel>
                    </Paper>
                </div>
                <div style={{ display: "flex", flexDirection: "column", width: 354 }}>
                    {actions.map((val, index) => {
                        return (
                            <div style={{ display: "flex", flexDirection: "row", paddingTop: index === 0 ? "20px" : "80px", borderTop: index === 0 ? "0" : "1px solid black", paddingBottom: "80px" }}>
                                <div style={{ width: "40vw", marginLeft: "40px" }}>
                                    <h1>{actions[index]?.name.replaceAll("_", " ")}</h1>
                                    <p style={{ marginLeft: "5px" }}>Verifies whether 3D Secure is available for the specified BIN or card brand. For 3D Secure 2, this endpoint also returns device fingerprinting keys.  For more information, refer to 3D <a>Secure 2.</a></p>
                                    <span style={{ fontSize: "18px", fontWeight: "600" }}>Attribute</span>
                                    <hr />
                                    {moredata[data[index]?.name] && moredata[data[index]?.name].properties ? (
                                        Object.entries(moredata[data[index]?.name].properties).map(([key, value]) => (
                                            <div style={{ marginLeft: "15px" }} key={key}>
                                                <pre><b>{key}</b> <span style={{ color: "grey" }}>{value?.type}</span></pre>
                                                <p style={{ marginLeft: "19px" }}>{value?.description}</p>
                                                <hr />
                                            </div>
                                        ))
                                    ) : (
                                        <div></div>
                                    )}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
    //<h1 style={{padding: "16px 32px", margin: "40px 120px 0px 120px"}}>{curData?.name}</h1>

    // <div style={{display: "flex", borderRight: "1px solid black", width: "15%", height: "91vh"}}>
    // </div>
    //    <div style={{"display": "flex", "flexDirection": "column", minminHeight: "100%"}}>
    //        <div style={{"display": "flex",paddingLeft: "21px",alignItems: "center", widht: "100%", minHeight: "100%" ,backgroundColor: "red"}}>
    //            <img alt={info.title} src={info["x-logo"] ? info["x-logo"] : `https://ui-avatars.com/api/?name=${info.title}`} width={60} minHeight={60} />
    //            <h1 style={{paddingLeft: "5px"}}>
    //                {info.title}
    //            </h1>
    //	</div>
    //	<div style={{"display": "flex", "flexDirection": "row", minHeight: "100%"}}>
    //            <div style={{"display": "flex", "flexDirection": "column", "width": "15%", backgroundColor: "yellow", padding: "10px"}}>
    //            {actions.map((action, index)=> (
    //                <button key={`${index}`} id="test" style={{marginTop: "10px"}} onClick={() => handleChange(index)}>
    //                    {action?.name}
    //                </button>
    //            ))}
    //            </div>
    //            <div style={{"display": "flex", "flexDirection": "colomn", backgroundColor: "pink", width: "100%", minHeight: "100%"}}>
    //                <AppActions/>
    //                <div style={{"width": "40%", paddingLeft: "80px"}}>
    //                    <div style={{"display": "flex", "flexDirection": "colomn", justifyContent: "space-between"}}>
    //                        <h3>SAMPLE REQUEST</h3>
    //                        <button style={{
    //                              minHeight: "30px",
    //                              marginTop: "20px",
    //                              padding: "0 20px",
    //                              textAlign: "center",
    //                              display: "inline-block",
    //                              lineminHeight: "30px",
    //                              verticalAlign: "middle"
    //                            }}
    //                        onClick={handleExecution}>
    //                          Run
    //                        </button>
    //                    </div>
    //                    <ReactJson src={formData()} theme="monokai" displayObjectSize={false} displayDataTypes={false} iconStyle={"circle"} style={{fontWeight: "bold"}} onEdit={(edit) => { console.log(edit) }}/>
    //                    {isResponse ? <ProvideResponse /> : <div></div>}
    //                </div>
    //            </div>
    //	</div>
    //    </div>
}



export default ApiExplorer
