import React, { useState, useEffect } from "react";
import theme from '../theme.jsx';
//import { useAlert
import { ToastContainer, toast } from "react-toastify" 
import {isMobile} from "react-device-detect";
import {sortByKey} from "../views/AngularWorkflow.jsx";
import { useParams, useNavigate, Link } from "react-router-dom";
import UsecaseSearch from "../components/UsecaseSearch.jsx"

import {
	TextField,
	Grid, 
	Stepper,
	Step,
	StepLabel,
	StepContent,
	StepIcon,
	Dialog,
	DialogTitle,
	Tooltip,
	Zoom,
	Typography,
	Paper,
	FormControl,
	Button,
	DialogContent,
	InputLabel,
	Select,
	Menu,
	MenuItem,
	CircularProgress,
} from "@mui/material";

import { 
	AllInclusive as AllInclusiveIcon, 
	AutoAwesome as AutoAwesomeIcon, 
	Edit as EditIcon,  
	ArrowForward as ArrowForwardIcon,
} from '@mui/icons-material';

//import detectEthereumProvider from "@metamask/detect-provider";
import FAQItem from "../components/FAQ.jsx";
import CreatorGrid from "../components/CreatorGrid.jsx";

const Creators = (props) => {
  const { globalUrl, isLoggedIn, isLoaded, userdata, setUserData } = props
  //const alert = useAlert();
	let navigate = useNavigate();

  const [loadedItems, setLoadedItems] = React.useState([])
  //const [loadedValidationWorkflows, setLoadedValidationWorkflows] = React.useState([]);
  const [selfOwnedWorkflows, setSelfOwnedWorkflows] = React.useState([]);
  const [joinModalOpen, setJoinModalOpen] = React.useState(false);
  const [loadedWorkflowCollections, setLoadedWorkflowCollections] = React.useState([]);
	const viewTypes = ["NFT", "Workflow"] 
  const [viewType, setViewType] = React.useState(viewTypes[0])
  const [collection, setCollection] = React.useState("")
  const [joiningFinished, setJoiningFinished] = React.useState(false)
	const [selectedNft, setSelectedNft] = React.useState("")
  const [providerCheck, setProviderCheck] = React.useState({
		"provider": true,
		"metamask": true,
		"connected": false,
		"address": "",
	})


	const checkWorkflowOwner = (data, userdata) => {
    var currentOwner = false;
		if (userdata.eth_info === undefined || userdata.eth_info === null || userdata.eth_info.account === undefined || userdata.eth_info.account === null || userdata.eth_info.account.length === 0) {
			return currentOwner 
		}

    if (data.owner.address === userdata.eth_info.account) {
      currentOwner = true;
    } else {
      if (
        data.top_ownerships !== undefined &&
        data.top_ownerships !== null &&
        data.top_ownerships.length === 1
      ) {
        for (var key in data.top_ownerships) {
          if (
            data.top_ownerships[key].owner.address === userdata.eth_info.account
          ) {
            currentOwner = true;
            break;
          }
        }
      }
    }

    return currentOwner;
  }

	useEffect(() => {
  }, []);

	const MarketplaceItem = (props) => { 
    const { data, date } = props;
  	const [hovering, setHovering] = React.useState(false)

		if (data.name === "") {
			data.name = "Winner drawn December "+date
		} else {
			data.name = data.name.replace("-", " ")
		}

		const imageUrl = data.owner !== undefined && data.owner !== null && data.owner.includes("discord") ? data.owner : `https://opensea.io/${data.owner}`

		var imageFinished = true 
		if (data.image === undefined || data.image === null || data.image === "") {
			imageFinished = false 
		}

    const currentOwner = checkWorkflowOwner(data, userdata);
    if (currentOwner === true) {
      innerPaperStyle.border = "3px solid #f86a3e";
    }

		const baseWidth = isMobile ? "100%" : 312.68
		const gridStyle = {
		 	borderRadius: theme.palette.borderRadius, 
		 	minHeight: 426, 
		 	maxHeight: 426, 
		 	//maxWidth: isMobile ? baseWidth : "100%", 
		 	boxShadow: hovering ? 0 : 10,
		 	cursor: hovering ? "pointer" : "default",
		 	margin: "auto",
		 	marginTop: 20,
		}

    var innerPaperStyle = {
      backgroundColor: theme.palette.inputColor,
      display: "flex",
      flexDirection: "column",
      padding: "0px 0px 12px 0px",
      borderRadius: theme.palette.borderRadius,
			maxHeight: 376,
			minHeight: 376,
			overflow: "hidden",
			//borderBottom: "2px solid black",
    }

		const paperClick = () => {
			if (data.name === selectedNft.name) {
				return
			}

			setSelectedNft(data)

			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth",
			})
		}


		var collection = data.collection
		if (collection !== null && collection !== undefined) {
      collection = (collection.charAt(0).toUpperCase() + collection.substring(1)).replace("-", " ", -1)
		}

		const baseHeight = 313.4
		const circleSize = 70
		const image = imageFinished === true ? 
			<img
				src={data.image}
				alt={data.name}
				style={{ 
					width: "100%", 
					height: "100%",
					maxWidth: baseWidth,
					margin: "auto",
					borderRadius: "5px 5px 0px 0px",
				}}
			/>
			:
			<div style={{boxShadow: "inset 100px 0 0 2000px rgba(0,0,0,0.1)", height: "100%", width: "100%", backgroundImage: "linear-gradient(to bottom right, #f86a3e, #f34079)", position: "relative", minHeight: baseHeight}}>
				<div style={{position: "absolute", left: isMobile ? "40%" : baseWidth/2-(circleSize/2), top: baseHeight/2-(circleSize/2), width: circleSize, height: circleSize, borderRadius: 50, backgroundColor: "white", }}>
					<Typography style={{color: "rgba(0,0,0,0.7)", textAlign: "center", marginTop: 17, position: "relative", fontSize: 30,}} variant="h4" color="textSecondary">
						{date}
					</Typography>
				</div>
			</div>


    return (
      <Grid item xs={isMobile || date === undefined ? 12 : 4} style={gridStyle} onMouseOver={() => {setHovering(true)}} onMouseOut={() => {setHovering(false)}}>
        <Paper style={{backgroundColor: theme.palette.inputColor, border: selectedNft.id === data.id ? "2px solid #f86a3e" : "", boxShadow: selectedNft.id === data.id ? "0 0 10px #f86a3e" : ""}}>
					<Paper style={innerPaperStyle} onClick={() => {
						if (imageFinished === false || isMobile) {
							return
						}

						paperClick()
					}}>
						<div style={{minHeight: isMobile ? "100%" : baseHeight, maxHeight: isMobile ? "100%" : baseHeight, }}>
							{image}
						</div>
						<div style={{padding: 10, paddingTop: isMobile ? 20 : 10}}>
							<Typography variant="body2" color="textSecondary">
								{collection}
							</Typography>
							<Typography variant="body2">{data.name}</Typography>
							<Typography variant="body2">
								<a
									rel="noopener noreferrer"
									target="_blank"
									href={imageUrl}
									style={{ textDecoration: "none", color: "#f85a3e" }}
								>
									See winners
								</a>
							</Typography>
						</div>
					</Paper>
					{date !== undefined ? 
						<Typography style={{textAlign: "center"}} variant="h6" color="textSecondary">
							Dec. {date}	
						</Typography>
					: null}
				</Paper>
      </Grid>
    )
	}

	var delay = 0 
	var date = 0

	const BecomeACreator = () => {
		const [selected, setSelected] = useState(0)


		const buttonItems = [{
			"title": "Create apps & workflows",
			"image": "/images/build_a_workflow.png",
			"subtext": "Start by building. Any workflow and app can be shared, and we encourage you to release as many trigger, subflow and standalone workflows as you can.",
			"link": "/docs/creators#what_to_share",
		}, {
			"title": "Map them to usecases",
			"image": "/images/set_a_usecase.png",
			"subtext": "It is important that workflows you release are well defined. Shuffle automatically prioritize searches for workflows and apps that are well described and documented.",
			"link": "/docs/creators#correct_information",
		}, {
			"title": "Release them to the public",
			"image": "/images/publish_workflow.png",
			"subtext": "Releasing a workflows and apps are a single click. They are sanitized, and you can always modify them after they are released.",
			"link": "/docs/creators#release_workflows",
		}, {
			"title": "Track their usage",
			"image": "/images/clicks_and_conversions.png",
			"subtext": "Without information about searches, clicks and conversions, it's hard to decide what to do next. We give you the tools necessary to make good decisions.",
			"link": "/docs/creators#track_workflows_and_apps",
		}, {
			"title": "Get verified",
			"image": "/images/get_verified.png",
			"subtext": "To earn, you need to be a verified creator. The process is straight forward, but requires you to release relevant apps and workflows others are interested in",
			"link": "/docs/creators#get_verified",
		},
		{
			"title": "More tips",
			"image": "/images/meta/documentation.png",
			"subtext": "Anything that brings business to Shuffle is rewarded. Discord support, Videos, Blogs and everything that leads to more customers will have a direct impact on your earnings.",
			"link": "/docs/creators#tips_and_tricks",
		}
		]

		const textStyle = {
			cursor: "pointer",
			marginTop: 10,
			marginBottom: 10,
			height: 50, 
			paddingTop: 15,
		}

		return (
			<div style={{maxWidth: isMobile ? "100%" : adventWidth, minWidth: isMobile ? "100%" : adventWidth, margin: "auto",}}>

				<Typography variant="h1" style={{textAlign: "center", marginBottom: 50, marginTop: 100, }}>
					The Creator Ecosystem
				</Typography>

				<div style={{display: "flex"}}>
					<div style={{flex: 2}}>
						{buttonItems.map((data, index) => {
							return (
								<div style={textStyle} key={index} onClick={() => {
									setSelected(index)	
								}}>
									<Button variant="text" style={{textAlign: "left", padding: 10, minWidth: "100%", maxWidth: "100%", textTransform: "none", border: selected === index ? "1px solid #f86a3e" : "inherit",}}>
										<span style={{minWidth: 205, maxWidth: 205}}>
											{index+1}. {data.title} 
										</span>
										<ArrowForwardIcon style={{marginLeft: 20, }} />
									</Button>
								</div>
							)
						})}
					</div> 
					{isMobile ? null : 
						<div style={{flex: 4, marginLeft: 50, }}>
							<div style={{minHeight: 365, maxHeight: 365}}>
								<img
									src={buttonItems[selected].image}
									alt={buttonItems[selected].text}
									style={{ margin: "auto", width: "100%", height: "100%", borderRadius: theme.palette.borderRadius, border: "1px solid rgba(255,255,255,0.3)", }}
								/>
							</div>
							<Typography variant="body1" style={{marginTop: 10, }}>
								{buttonItems[selected].subtext}
							</Typography>
							<Button variant="contained" color="primary" disabled={buttonItems[selected].link === undefined || buttonItems[selected].link === ""} style={{marginTop: 10, textTransform: "none",}} onClick={() => {
								navigate(buttonItems[selected].link)
							}}>
								Learn more 
							</Button> 
						</div> 
					}
				</div>

					
			</div>
		)
	}

	// 1024 - spacing (10*3+5*3)
	const adventWidth = 979
	const gridView = 
		<div style={{maxWidth: isMobile ? "100%" : adventWidth, minWidth: isMobile ? "100%" : adventWidth, margin: "auto",}}>
			<Typography variant="h1" style={{textAlign: "center", marginBottom: 50, marginTop: 100, }}>
				Find creators
			</Typography>

			<CreatorGrid parsedXs={4} showSuggestion={false} globalUrl={globalUrl} isMobile={isMobile} userdata={userdata} />
		</div>


	const MainHovering = () => {
  	const [hovering, setHovering] = React.useState(false)

		const paperStyle = {
			 	borderRadius: theme.palette.borderRadius, 
			 	minHeight: 376, 
			 	maxHeight: 376, 
			 	minWidth: 300, 
			 	maxWidth: 300, 
			 	cursor: hovering ? "pointer" : "default",
			 	marginLeft: 100,
				backgroundColor: theme.palette.inputColor, 
				border: "2px solid #f86a3e",
				boxShadow: "0 0 10px #f86a3e",
		}

		const data = selectedNft

		return (
			<Paper style={paperStyle}>
				<img
					src={data.image}
					alt={data.name}
					style={{ width: "100%", borderRadius: "5px 5px 0px 0px",}}
				/>
				<div style={{padding: "0px 5px 5px 10px",}}>
					<Typography variant="body2" color="textSecondary">
						{data.collection}
					</Typography>
					<Typography variant="body2">{data.name}</Typography>
					<Typography variant="body2">
						<a
							rel="noopener noreferrer"
							target="_blank"
							href={data.workflow}
							style={{ textDecoration: "none", color: "#f85a3e" }}
						>
							Workflow
						</a>
					</Typography>
				</div>
			</Paper>
		)
	}

	const BecomeCreatorComponent = (props) => {
  	const { joiningFinished } = props

		const [activeStep, setActiveStep] = React.useState(joiningFinished === true ? 3 : 0);
		const [walletInfo, setWalletInfo] = React.useState({});
  	const [_, setUpdate] = React.useState(""); // Used for rendring, don't remove
		const [providerDone, setProviderDone] = React.useState(false)
		const [providerInfo, setProviderInfo] = React.useState({
			"firstname": "",
			"lastname": "",
			"email": "",
			"twitter": "",
			"address": providerCheck.address.length > 0 ? providerCheck.address : "",
		})

		const steps = [
			{
				label: 'Connect to Github',
				description: `By connecting to Github, we use your profile photo and contribution information to Shuffle's repositories as way to understand how you're contributing to Shuffle.`,
				icon: AllInclusiveIcon 
			},
			{
				label: 'Add additional information',
				description:
					'To ensure we can pay out, we need to know who you are. This data will be saved, but not shared publicly.',
				icon: EditIcon 
			},
			{
				label: 'Get verified!',
				description: 
					`The last step is to get you verified! By releasing or maintaining Workflows and Apps in Shuffle, you will get verified. Click below to go to your creator profile!`,
				icon: AutoAwesomeIcon,  
			},
		]

		const submitUserValues = (info) => {
			const url = `${globalUrl}/api/v1/join_prizedraw`
			fetch(url, {
				mode: "cors",
				method: "POST",
				body: JSON.stringify(info),
				credentials: "include",
				crossDomain: true,
				withCredentials: true,
				headers: {
					"Content-Type": "application/json; charset=utf-8",
				},
			})
      .then((response) => {
        if (response.status !== 200) {
          console.log("Status not 200 for WORKFLOW EXECUTION :O!");
					setActiveStep(2);
        } else {
					setActiveStep(3);
				}

        return response.json()
      })
			.then((responseJson) => {
				//toast("Data: ", responseJson.reason)
			})
			.catch((error) => {
				setActiveStep(2);
				toast("Error: "+error)
			});
		}

		const handleGithubConnection = () => {
  		//result = RestClient.post('https://github.com/login/oauth/access_token',

			console.log("HOST: ", window.location.host);
			console.log("Location: ", window.location);
			const redirectUri = window.location.host === "localhost:3002"
					? "http%3A%2F%2Flocalhost:3002%2Fset_authentication"
					: "https%3A%2F%2Fshuffler.io%2Fset_authentication"

			console.log("redirect: ", redirectUri)

			const client_id = "3d272b1b782b100b1e61"
			const username = userdata.id;
			const scopes = "read:user";

			const url = `https://github.com/login/oauth/authorize?access_type=offline&prompt=consent&client_id=${client_id}&redirect_uri=${redirectUri}&response_type=code&scope=${scopes}&state=username%3D${username}%26type%3Dgithub`
			var newwin = window.open(url, "", "width=800,height=600");

			// Check whether we got a callback somewhere
			var id = setInterval(function () {
				fetch(globalUrl+"/api/v1/me", {
					credentials: "include",
					headers: {
						'Content-Type': 'application/json',
					},
				})
				.then(response => {
					if (response.status === 401) {
						// 1. Find the current location
						// 2. Add it to view
						if (window.location.pathname === "/admin") {
							window.location = `/login?view=admin&message=You must log in first`
							//console.log("WINDOW: ", window.location)
						}
					}

					return response.json()
				})
				.then(responseJson => {
					if (responseJson.success === true) {
						if (responseJson.public_username !== undefined && responseJson.public_username !== null && responseJson.public_username.length > 0) {
							setUserData(responseJson)
              				newwin.close();
            				clearInterval(id);
						}
					}
				})
				.catch(error => {
					console.log("Failed to get: ", error)
				})
			}, 2500);

			//saveWorkflow(workflow);
		}

		const handleNext = () => {
			if (activeStep === steps.length-1) {
				submitUserValues(providerInfo)			
			} else {
				setActiveStep((prevActiveStep) => prevActiveStep + 1);
			}

			if (activeStep === steps.length-1) {
				console.log("In the active step")
				navigate(`/creators/${userdata.public_username}`)
  				setJoiningFinished(true)
        		localStorage.setItem("join_finished", "true");
			}
		}

		const handleBack = () => {
			if (activeStep === 2) {
				console.log("FIXED!:)")
			}

			setActiveStep((prevActiveStep) => prevActiveStep - 1);
		}

		const handleReset = () => {
			setActiveStep(0);
		}

		/*
		const handleMMSetup = async () => {	
			//const provider = window.ethereum
			//console.log("Provider: ", provider)
			const provider = await detectEthereumProvider();
			if (!provider) {
				console.log("Please install MetaMask!");
				const newWindow = window.open("https://metamask.io/download");

				if (isMobile) {
					//https://metamask.io/download.html
					console.log("Mobile!")
				} else {
					console.log("Browser!")
				}

				toast(
					"Please download the MetaMask browser extension to authenticate fully!"
				)
				return;
			}

			console.log("Provider: ", provider)
			if (!provider.isMetaMask) {
				toast("Only MetaMask is supported as of now. Please refresh, and contact us if this persists.");
				return;
			}	

			if (!provider.isConnected()) {
				toast("Metamask is not connected. Please refresh, and contact us if this persists.");
				return;
			}

			console.log("Running request for account")
			var method = "eth_requestAccounts";
			var params = [];
			provider
				.request({
					method: method,
					params,
				})
				.then((result) => {
					console.log(result)
					if (result !== undefined && result !== null && result.length > 0) {
						//providerCheck.address = result[0]
						//setProviderCheck(providerCheck)

						providerInfo["address"] = result[0]
						setProviderInfo(providerInfo)
      			setUpdate(Math.random());
					}
				})
        		.catch((error) => {
					console.log("Error: ", error)
				})
		}
		*/

		console.log("Userdata:", userdata)
		return (
			<Dialog
				open={joinModalOpen}
				transition={Zoom}
				onClose={() => {
					setJoinModalOpen(false)
				}}
				PaperProps={{
					style: {
						backgroundColor: theme.palette.surfaceColor,
						color: "white",
						minWidth: isMobile ? "90%" : 500,
						maxWidth: isMobile ? "90%" : 500,
						padding: isMobile ? 5 : 15,
					},
				}}
			>
				<DialogTitle style={{ marginBottom: 0 }}>
					<div style={{ textAlign: "center", color: "rgba(255,255,255,0.9)" }}>
						Sign up as a Creator	
					</div>
				</DialogTitle>
				<DialogContent>
					<Typography color="textSecondary" variant="body2">
						By connecting your Github account, you should have read the <Link to="/docs/creators" style={{ textDecoration: "none", color: "#f85a3e" }}>Creator documentation</Link>, agree to our <Link to="/docs/terms_of_service" style={{ textDecoration: "none", color: "#f85a3e" }}>Terms of Service</Link>, and acknowledge that your account will be turned into a creator account. This enables you to earn an income from Shuffle's growth. This IS reversible by you. Support: support@shuffler.io
					</Typography>
					<Stepper activeStep={activeStep} orientation="vertical" style={{marginTop: 10, backgroundColor: theme.palette.inputColor, borderRadius: theme.palette.borderRadius,}}>
						{steps.map((step, index) => (
							<Step key={step.label}>
								<StepLabel
									StepIconComponent={step.icon}
									optional={
										index === 2 ? (
											null
										) : null
									}
								>
									{step.label}
								</StepLabel>
								<StepContent>
									<Typography variant="body2">
										{step.description}
									</Typography>
									{index === 0  ? 
										<div>
											{providerCheck.provider === false ? 
												<Typography style={{}} variant="body2">
													After installing the browser extension, please refresh this window!
												</Typography>
											: null}
											<Button
												variant="contained"
												color="primary"
												fullWidth
												style={{
													textTransform: "none",
													textAlign: "left",
													justifyContent: "flex-start",
													marginTop: 10,
													padding: 0,
													backgroundColor: "black",
													color: "white",
													height: 50,
												}}
												onClick={() => {
													handleGithubConnection()
												}}
											>
												<img
													alt=""
													style={{ margin: 0, marginLeft: 10, height: 30, width: 30, }}
													src="/images/social/resized/github.png"
												/>
												<Typography style={{ margin: 0, marginLeft: 10 }} variant="body1">
													Connect to Github
												</Typography>
											</Button>
										</div>
									: index === 2 ?
										<div style={{marginTop: 5, border: "1px solid rgba(255,255,255,0.7)", borderRadius: theme.palette.borderRadius, overflow: "hidden", padding: 5, }}>
											<Typography variant="body2" color="textSecondary">
												Name: {providerInfo.firstname}&nbsp;{providerInfo.lastname}
											</Typography>
											<Typography variant="body2" color="textSecondary">
												Email: {providerInfo.email} 
											</Typography>
											<Typography variant="body2" color="textSecondary">
												Wallet address: {providerInfo.address} 
											</Typography>
										</div>
									: index === 1 ?
										<div>
											<div style={{display: "flex"}}>
												<TextField
													style={{ backgroundColor: theme.palette.inputColor, flex: "1" }}
													InputProps={{
														style: {
															height: "50px",
															color: "white",
														},
													}}
													color="primary"
													required
													fullWidth={true}
													label="First name"
													value={providerCheck.firstname}
													placeholder="First name"
													type="firstname"
													id="standard-required"
													autoComplete="given-name"
													margin="normal"
													variant="outlined"
													onChange={(e) => {
														providerInfo.firstname = e.target.value
														setProviderInfo(providerInfo)
      											setUpdate(Math.random())

														if (e.target.value.length === 0) {
															setProviderDone(false)
															return
														}
			
														if (!providerDone && providerInfo.firstname !== "" && providerInfo.lastname !== "" && providerInfo.email !== "") {
															setProviderDone(true)
														}
													}}
												/>
												<TextField
													style={{ backgroundColor: theme.palette.inputColor, flex: "1" }}
													InputProps={{
														style: {
															height: "50px",
															color: "white",
														},
													}}
													color="primary"
													required
													fullWidth={true}
													value={providerCheck.lastname}
													label="Last name"
													placeholder="Last name"
													type="firstname"
													id="standard-required"
													autoComplete="additional-name"
													margin="normal"
													variant="outlined"
													onChange={(e) => {
														providerInfo.lastname = e.target.value
														setProviderInfo(providerInfo)

														if (e.target.value.length === 0) {
															setProviderDone(false)
															return
														}
			
														if (!providerDone && providerInfo.firstname !== "" && providerInfo.lastname !== "" && providerInfo.email !== "") {
															setProviderDone(true)
														}
      											setUpdate(Math.random())
													}}
												/>
											</div>
											<TextField
												style={{ backgroundColor: theme.palette.inputColor, flex: "1" }}
												InputProps={{
													style: {
														height: "50px",
														color: "white",
													},
												}}
												color="primary"
												required
												fullWidth={true}
												value={providerCheck.email}
												label="Email"
												placeholder="Email"
												type="firstname"
												id="standard-required"
												autoComplete="email"
												margin="normal"
												variant="outlined"
												onChange={(e) => {
													providerInfo.email = e.target.value
													//console.log(providerInfo)
													//if (e.target.value.includes("@") && e.target.value.includes(".")) {

													//}
													setProviderInfo(providerInfo)
													if (e.target.value.length === 0) {
														setProviderDone(false)
														return
													}
		
													if (!providerDone && providerInfo.firstname !== "" && providerInfo.lastname !== "" && providerInfo.email !== "") {
														setProviderDone(true)
													}
      										setUpdate(Math.random())
												}}
											/>
											<TextField
												style={{ backgroundColor: theme.palette.inputColor, flex: "1" }}
												InputProps={{
													style: {
														height: "50px",
														color: "white",
													},
												}}
												color="primary"
												fullWidth={true}
												value={providerCheck.email}
												label="Twitter Account (optional)"
												placeholder="@shuffleio"
												type="twitter_account"
												id="standard-required"
												autoComplete="twitter"
												margin="normal"
												variant="outlined"
												onChange={(e) => {
													providerInfo.twitter = e.target.value
													setProviderInfo(providerInfo)
      										setUpdate(Math.random())
												}}
											/>
										</div>
									:null}
									<div style={{marginTop: 10,}} sx={{ mb: 2, }}>
										<div>
											<Button
												variant="contained"
												color="primary"
												onClick={() => {
													handleNext()
												}}
												disabled={(index === 0 && userdata.public_username === "") || (index === 1 && !providerDone)}
												sx={{ mt: 1, mr: 1 }}responseJson
												style={{}}
											>
												{index === steps.length - 1 ? 'Submit' : 'Next'}
											</Button>
											<Button
												disabled={index === 0}
												onClick={() => {
													handleBack()
												}}
												sx={{ mt: 1, mr: 1 }}
											>
												Back
											</Button>
										</div>
									</div>
								</StepContent>
							</Step>
						))}
					</Stepper>
					{activeStep === steps.length && (
						<Paper square elevation={0} style={{padding: 15, backgroundColor: theme.palette.inputColor, borderRadius: theme.palette.borderRadius, marginTop: 10, }} sx={{ p: 3 }}>
							<Typography>All steps completed! We'll contact you if you won.</Typography>
							{/*<Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
								Reset
							</Button>*/}
						</Paper>
					)}


				</DialogContent>
			</Dialog>
		)
	}

		const earningsPaperStyle = {
				flex: 1, 
				padding: 35,
				borderRadius: theme.palette.borderRadius,
				backgroundColor: theme.palette.inputColor,
				margin: 10, 
				textAlign: "center",
		}

		const ViewSample = () => {
				return (
					<div>
						<Typography variant="h6">
						- Overview of app and workflow stats
						- How it works! Share enough info.
						- Sample app highlights
						- Sample worfklow highlights 
						- Add App and Workflow search discovery data
						- FAQ
						- Extra for Creators only: App & Workflow search stats everywhere 
						- Creator interviews
						- Creator timelines - what have they done over time?
						- Startdate for payouts!
						- Video from the launch
						- Standardized workflow TYPES
						- Configure your profile (how)
						- What does the "hire" button mean?
						- Plagiarizm
						</Typography>
					</div>
				)
			}

		const EarningsOverview = () => {
			return (
				<div style={{maxWidth: isMobile ? "100%" : 768, minWidth: isMobile ? "100%" : 768, margin: "auto", marginTop: 150, }}>
					<Typography variant="h1" style={{textAlign: "center", }}>
						2022 Earnings overview
					</Typography>
					<div style={{display: "flex", flexDirection: isMobile ? "column" : "row",}}>
						<Paper
							square
							style={earningsPaperStyle}
						>
							<Typography variant="h6" style={{marginTop: 8}}>
								$ TBD
							</Typography>
							<Typography variant="body1" color="textSecondary">
								2022 Revenue
							</Typography>
						</Paper>
						<Paper
							square
							style={earningsPaperStyle}
						>
							<img
								src="/images/social/ethereum.png"
								alt="ethereum-icon"
								style={{ height: 30 }}
							/>
							<Typography>
								TBD ETH
							</Typography>
							<Typography variant="body1" color="textSecondary">
								Avg. NFT value	
							</Typography>
						</Paper>
						<Paper
							square
							style={earningsPaperStyle}
						>
							<Typography variant="h6">
								10.0%
							</Typography>
							<Typography variant="body1" color="textSecondary">
								Revenue to be shared
							</Typography>
						</Paper>
					</div>
				</div>
			)
		}

		const FAQView = () => {
			const questions = [
			{
				"question": "Can an organization be a creator?",
				"answer": "",
			},
			{
				"question": "How to become a verified creator?",
				"answer": "",
			},
			{
				"question": "What do I need make to get benefits?",
				"answer": "",
			},
			{
				"question": "How do I make my apps or workflows public?",
				"answer": "",
			},
			{
				"question": "How do I track my apps?",
				"answer": "",
			},
			{
				"question": "What workflow should I make?",
				"answer": "",
			},
			{
				"question": "What app should I make?",
				"answer": "",
			},
			{
				"question": "What other content can I create for the community?",
				"answer": "",
			},
			{
				"question": "How much can I make?",
				"answer": "",
			},
			{
				"question": "How do I receive the money I earn?",
				"answer": "",
			},
			{
				"question": "Where can I track my content?",
				"answer": "",
			},
			{
				"question": "Where can I track my earnings?",
				"answer": "",
			},
			]

			return (
				<div style={{maxWidth: isMobile ? "100%" : 768, margin: "auto", marginTop: 150, }}>
					<Paper style={{minHeight: 200, backgroundColor: theme.palette.surfaceColor, padding: isMobile ? "50px 10px 50px 10px" : 50, }}>
						<Typography variant="h1" style={{textAlign: "center", marginBottom: 25, }}>
							FAQ	
						</Typography>
						{questions.map((data, index) => {
							return (
								<FAQItem key={index} question={data.question} answer={data.answer} />
							)
						})}
					</Paper>
				</div>
			)
		}

		const headerInfo = 
			<div style={{height: 586, background: "rgba(0,0,0,0.3)", boxShadow: "inset 100px 0 0 2000px rgba(0,0,0,0.8)", backgroundSize: "cover", backgroundImage: selectedNft === "" ? `url(/images/meta/creators.png)` : `url(${selectedNft.image})`}}>
				<div style={{display: "flex", width: isMobile ? "100%" : selectedNft === "" ? 900 : 768, margin: "auto", }}>
					<div style={{flex: 1, paddingTop: 150, margin: isMobile ? 10 : 0, maxWidth: 350, minWidth: 350, }}>
						<Typography variant="h1" color="textSecondary" style={{color: "white"}}>
							Create, share and earn with Shuffle
						</Typography>
						<Typography variant="body1" style={{marginTop: 10}}>
							By being a Creator at Shuffle, you'll be part of a skilled community of security practitioners. We focus on making the world better by making sharable content together. 
						</Typography>
						<div style={{display: "flex", marginTop: 20, }}>
							{/*userdata.public_username !== undefined && userdata.public_username !== null && userdata.public_username.length > 0 ? 
								<Button
									variant="contained"
									style={{
										borderRadius: 25, 
										textTransform: "none", 
										backgroundImage: "linear-gradient(to right, #f86a3e, #f34079)",
										height: 45, 
									}}
									onClick={() => {
										navigate(`/creators/${userdata.public_username}`)
									}}
									color="primary"
								>
									Go to your profile
								</Button>
								:*/}
								<Button
									variant="contained"
									style={{
										borderRadius: 25, 
										textTransform: "none", 
										backgroundImage: "linear-gradient(to right, #f86a3e, #f34079)",
										height: 45, 
									}}
									onClick={() => {
										if (userdata.username === undefined || userdata.username === null) {
											navigate("/login?message=You must be logged in to become a creator&view=/creators")
										} else {
											setJoinModalOpen(true)
										}
									}}
									color="primary"
								>
									Become a creator
								</Button>
							{/**/}
							<Link 
								to={`/docs/creators`}
								style={{ textDecoration: "none", color: "#f85a3e" }}
							>
								<Button
									variant="outlined"
									style={{
										marginLeft: 10, 
										borderRadius: 25, 
										textTransform: "none",
										height: 50, 
									}}
									onClick={() => {
										
									}}
									color="secondary"
								>
									How it works
								</Button>
							</Link>
						</div>
					</div>
					{isMobile ? null : 
						<div style={{flex: 1, marginLeft: 25, maxWidth: 400, minWidth: 400, position: "relative", marginTop: 180, }}>
							<UsecaseSearch
								globalUrl={globalUrl}
								defaultSearch={"Enrichment"}
								appFramework={{}}
								showTitle={false}
								canExpand={false}
								userdata={userdata}
							/>
						</div>
					}
				</div>
			</div>

	return (
		<div style={{paddingBottom: 250,}}>
			{headerInfo}
			<BecomeCreatorComponent joiningFinished={joiningFinished}/>

			<div style={{margin: isMobile ? "0px 10px 0px 10px" : "auto",}}>
				<BecomeACreator />
				{gridView}
				{/*
				<FAQView />
				<EarningsOverview />
				<ViewSample />
				*/}
			</div>
		</div>
	)
}

export default Creators 
