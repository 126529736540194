import React, { useState, useEffect, } from "react";
import { toast } from "react-toastify";
import theme from '../theme.jsx';
import { Link, Route, Routes, BrowserRouter, useNavigate } from "react-router-dom";

import {
  	Paper,
	Grid,
  	Typography,
	Chip,
	Select,
	MenuItem,

	InputLabel,
	FormControl,
} from "@mui/material";

import {
	red,
	green,
} from "../views/AngularWorkflow.jsx"

const Detections = (props) => {
	const { globalUrl } = props
	let navigate = useNavigate();

	const [detections, setDetections] = useState([])

	const handleLoadDetections= () => {
	  const url = `${globalUrl}/api/v1/detections`;

	  fetch(url, {
		method: "GET",
		credentials: "include",
		headers: {
		  "Content-Type": "application/json",
		},
	  })
		.then((response) =>
		  response.json().then((responseJson) => {
			  if (responseJson.success === false) {
				  toast("Failed to load detections")
			  } else {
				  setDetections(responseJson)
			  }
		  })
		)
		.catch((error) => {
		  console.log(`Error in connecting to SIEM: `, error);
		  toast("An error occurred while connecting to SIEM");
		});
	}

	useEffect(() => {
		handleLoadDetections()
	}, [])

	const DetectionItem = (detectionprops) => {
		const { data } = detectionprops

		const [hovering, setHovering] = useState(false)

		if (data === undefined || data === null) {
			return null
		}

		if (data.active === undefined || data.active === null) {
			data.active = false
		}

		if (data.alerts === undefined || data.alerts === null) {
			data.alerts = {
				"total": 23,
				"new": 4,
			}
		}

		const chipStyle = data.active === true ? 
			{
				color: green,
				borderColor: green,
			}
			:
			{
				color: red,
				borderColor: red,
			}

		return (
			<Paper
				onMouseEnter={() => setHovering(true)}
				onMouseLeave={() => setHovering(false)}
				style={{
					borderRadius: theme.palette.borderRadius,
					padding: "20px 30px 20px 30px", 
					border: hovering ? "1px solid white" : null,
					backgroundColor: theme.palette.surfaceColor,
				}}
			>
				<div style={{display: "flex", }}>
					<Link to={`/detections/${data.detection_name}`} style={{textDecoration: "none", color: "inherit", }}>
						<div 
							style={{
								flex: 1,  
								cursor: hovering ? "pointer" : "default",
							}}
						>
							<Typography>
								{data.category}
							</Typography>
						</div>
					</Link>
					<div style={{flex: 1,  textAlign: "right"}}>
						<Chip
                          style={chipStyle}
                          label={data.active ? "Active" : "Inactive"}
                          variant="outlined"
                          color="primary"
						  onClick={(e) => {
							  console.log("Click")
							  e.preventDefault()
							  e.stopPropagation()
						  }}
						/>
					</div> 
				</div>
				<div style={{display: "flex", }}>
					<Typography variant="body2" color="textSecondary">
						{data.alerts.total} alerts
					</Typography>
				</div>
				<div style={{display: "flex", marginTop: 15, }}>
				  <FormControl style={{flex: 1, }}>
					<InputLabel id="action-1">
						Forward to
					</InputLabel>
					<Select
					  disabled
					  defaultValue={"2"}
					  onChange={(e) => {
						  e.preventDefault()
						  e.stopPropagation()
					  }}
					  style={{
						backgroundColor: theme.palette.inputColor,
						color: "white",
					  }}
					>
						<MenuItem
							key={"123"}
							style={{ backgroundColor: theme.palette.inputColor, color: "white" }}
							value={"1"}
						  >
							1
                  		</MenuItem>
						<MenuItem
							key={"1234"}
							style={{ backgroundColor: theme.palette.inputColor, color: "white" }}
							value={"2"}
						  >
							2
                  		</MenuItem>
					</Select>
				  </FormControl> 
				  <FormControl style={{flex: 1, marginLeft: 5, }}>
					<InputLabel id="action-2">
						With Modifiers	
					</InputLabel>
					<Select
					  disabled
					  defaultValue={"2"}
					  onChange={(e) => {
						  e.preventDefault()
						  e.stopPropagation()
					  }}
					  style={{
						backgroundColor: theme.palette.inputColor,
						color: "white",
					  }}
					>
						<MenuItem
							key={"123"}
							style={{ backgroundColor: theme.palette.inputColor, color: "white" }}
							value={"1"}
						  >
							1
                  		</MenuItem>
						<MenuItem
							key={"1234"}
							style={{ backgroundColor: theme.palette.inputColor, color: "white" }}
							value={"2"}
						  >
							2
                  		</MenuItem>
					</Select>
				  </FormControl> 
				</div>
			</Paper>
		)
	}

	return (
		<div style={{width: 750, margin: "auto", }}>
			<Typography variant="h4" style={{marginTop: 50, }}>
				Detections
			</Typography>
			<Typography variant="body1" style={{marginTop: 10, }}>
				Different detection types yay
			</Typography>
			<Grid container spacing={2} style={{marginTop: 25, }}>
				{detections.map((detection, index) => {
					console.log("DETECTION: ", detection)
					return (
						<Grid item xs={6}>
							<DetectionItem data={detection} />
						</Grid>
					)
				})}
			</Grid>

		</div>
	)
}

export default Detections;
